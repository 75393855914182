<template>
  <v-row no-gutters>
    <v-col cols="12" class="pa-0">
      <skeleton-list v-if="$store.state.accounts.LoaderMe || loading" />
      <template v-else>
        <PermissionDenied class="mt-n10" v-if="!$helpers.hasSomePermission(['view_purchase'])" />
        <template v-else>
          <v-empty-state :class="{'mt-1' : routeList.includes($route.name)}" v-if="!purchasesList.length || (!routeList.includes($route.name) && Object.keys($route?.query).length === 0)" type="documents" id="documentos"
          :customClass="routeList.includes($route.name) ? 'pb-2' : ''" :isFree="routeList.includes($route.name)" title="No existen documentos para mostrar" :height="routeList.includes($route.name) ? 199 : 0" :hiddenDescription="hiddenBtnEmpty">
            <template v-slot:actions>
              <template v-if="!routeList.includes($route.name) && hiddenBtnEmpty">
                <v-btn @click="$emit('activeBtnFilter')" color="primary" :ripple="false" :width="153"><v-icon class="mr-2">mdi-filter-plus</v-icon>Filtrar</v-btn>
                <v-btn class="ml-2" @click="latestRecords()" outlined :ripple="false" width="153"><v-icon class="mr-2">mdi-text-search-variant</v-icon>Últimos registros</v-btn>
              </template>
            </template>
          </v-empty-state>
          <template v-else>
            <v-data-table
              :class="[{'table-not-rounded' : ['SupplierRetrieveUpdate'].includes($route.name)}, {'fixed-table' : $store.state.base.isExpandNavigationDrawer}]"
              :headers="!routeList.includes($route.name) ? currentHeader : headersDetail"
              :items="purchasesList"
              :items-per-page="pageSize || pagination"
              hide-default-header
              disable-sort
              mobile-breakpoint
              hide-default-footer
              @click:row="(purchasesList) => {goTo(purchasesList.id)}"
              style="cursor: pointer;"
              v-model="selected"
              item-key="id"
              :show-select="!routeList.includes($route.name)"
              checkbox-color="primary"
              :fixed-header="!routeList.includes($route.name)"
              :height="heightTable"
            >
              <!-- header -->
              <template v-slot:header="{ props: { headers } }">
                <VTableHeaders :headers="headers" @sort="setSort" @toggleAll="toggleAll" @isSelected="isSelected = $event" :resetCheckbox="resetCheckbox" @toggleDropdown="toggleDropdown = $event" />
                <v-divider style="position: absolute;  margin-top: -6px; margin-left: -5px ;z-index: 2; min-width: 100%" />
              </template>
              <!-- end header -->
              <template v-slot:[`item.supplier`]="{item}">
                <template v-if="!!Object.keys(item.supplier || {}).length && typeof item.supplier === 'object'">
                  <span :class="$route.name === 'SupplierRetrieveUpdate' ? 'body-2' : 'body-1 mb-n2'" class="d-inline-block text-truncate" style="max-width: 95%;">{{((item || {}).supplier || {}).name}}</span>
                  <span class="caption fontDraw--text d-block" v-if="((item || {}).supplier || {}).tax_id">{{$t('generals.RUT')}} {{item.supplier.tax_id}}</span>
                </template>
                <span class="body-2 text--disabled font-italic" v-else>Sin especificar</span>
              </template>
              <template v-slot:[`item.document_type`]="{item}">
                <span class="text-decoration-none fontBody--text">{{item.document_type | nameDocumentsType}}</span>
              </template>
              <template v-slot:[`item.number`]="{item}">
                <span class="body-1">{{item.number}}</span>
              </template>
              <template v-slot:[`item.date`]="{item}">
                <span class="body-1">{{item.date | date}}</span>
              </template>
              <template v-slot:[`item.date_recep_sii`]="{item}">
                <span class="body-1" v-if="item.date_recep_sii">{{item.date_recep_sii | dateTimeSecond}}</span>
                <span class="body-2 text--disabled font-italic" v-else>Sin especificar</span>
              </template>
              <template v-slot:[`item.status`]="{item}">
                <chip-status style="white-space: nowrap !important" :status="item?.status ? item.status : 'ERM'" :isTooltip="true" />
              </template>
              <template v-slot:[`item.due_date`]="{item}">
                <span v-if="item.due_date">{{item.due_date | date}}</span>
                <span v-else>---</span>
              </template>
              <template v-slot:[`item.created`]="{item}">
                <span>{{item.created | dateTimeSecond}}</span>
              </template>
              <template v-slot:[`item.labels`]="{item}">
                <div style="position: relative" v-if="item.labels.length">
                  <div class="d-flex ">
                    <div v-for="(label, i) in item.labels" :key="label.id">
                      <v-chip color="bgSearch" class="chip-labels" v-if="item.labels.length <= 3" :style="i !== 0 && i < 4 ? i === 2 ? 'position: absolute; margin-left: -5px' : 'position: absolute; margin-left: -55px' : ''">
                        <v-icon :color="label.color">mdi-circle</v-icon>
                        <span class="ml-2 body-2 fontBody--text d-inline-block text-truncate" v-if="item.labels.length < 4" :style="item.labels.length === 1 ? '' : 'max-width: 62px;'">{{ label.name }}</span>
                      </v-chip>
                    </div>
                  </div>
                  <div class="d-flex justify-space-between">
                    <v-tooltip color="bgSearch" right transition="scale-transition" max-width="300">
                      <template v-slot:activator="{on}">
                      <div v-for="(label, i) in item.labels" :key="label.id" v-on="on" >
                        <v-chip v-if="item.labels.length > 3" color="bgSearch" class="chip-labels text-center" small :style="item.labels.length > 4 && i !== 0 ? 'position: absolute; margin-left: -45px;' : ''">
                          <v-icon class="pl-1" :color="label.color">mdi-circle</v-icon>
                        </v-chip>
                      </div>
                    </template>
                    <div class="py-2">
                      <span class="d-block px-3 py-1" v-for="(label) in item.labels" :key="label.id">
                        <v-icon class="pr-1" :color="label.color" x-small>mdi-circle</v-icon>
                        {{ label.name }}
                      </span>
                    </div>
                    </v-tooltip>
                  </div>
                </div>
                <span class="body-2 text--disabled font-italic" v-else>Sin etiqueta asignada</span>
              </template>
              <template v-slot:[`item.total`]="{item}">
                <div class="text-right">
                  <span class="body-1">{{item.total | currency(item.document_type)}}</span>
                  <span class="fontDraw--text caption ml-1">{{item.document_type | firstDocumentsTypeCurrency}}</span>
                </div>
              </template>
              <template v-slot:[`item.actions`]="{item}">
                <v-menu close-on-click close-on-content-click offset-y left :nudge-bottom="10" :nudge-left="10" transition="slide-y-transition">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn class="mr-n4" v-bind="attrs" v-on="on" text x-small><v-icon color="fontBody" size="24">mdi-dots-vertical</v-icon></v-btn>
                  </template>
                  <v-list class="pa-0">
                    <v-list-item class="px-3" @click="current = item, renderDocument('purchases', null, 'pdf', item.id)" :ripple="false">
                      <v-list-item-title class="body-2"><v-icon color="fontBody" small left>mdi-text-box</v-icon>Ver PDF</v-list-item-title>
                    </v-list-item>
                    <v-list-item class="px-3" @click="downloadFile('purchases', `${item.document_type}_N${item.number}`, 'purchases', 'xml', item.id)" :ripple="false">
                      <v-list-item-title class="body-2"><v-icon color="fontBody" small left>mdi-code-braces-box</v-icon>Descargar XML</v-list-item-title>
                    </v-list-item>
                    <!-- <v-list-item class="px-3" @click="$emit('isActiveLabels', true), $emit('currentActionLabel', 'assign'), $emit('labelsPerDoc', item)" :disabled="!labelsList.length" :ripple="false">
                      <v-list-item-title class="body-2"><v-icon color="secondary" size="20" :disabled="!labelsList.length" left>mdi-label</v-icon>Etiquetar documento</v-list-item-title>
                    </v-list-item>
                    <v-list-item class="px-3" @click="$emit('isActiveLabels', true), $emit('currentActionLabel', 'delete'), $emit('labelsPerDoc', item)" :disabled="!item.labels.length" :ripple="false">
                      <v-list-item-title class="body-2"><v-icon color="secondary" :disabled="!item.labels.length" size="20" left>mdi-label-off</v-icon>Eliminar etiqueta</v-list-item-title>
                    </v-list-item> -->
                  </v-list>
                </v-menu>
              </template>
            </v-data-table>
            <VMainPagination :count="purchasesList?.length">
              <template v-slot:main v-if="$store.state.auth.account.date_update_purchase">
                <v-col class="body-2 fontDraw--text">Fecha de actualización: {{$store.state.auth.account.date_update_purchase | dateTime}}</v-col>
              </template>
            </VMainPagination>
          </template>
        </template>
      </template>
      <!-- dialog pdf -->
      <v-dialog v-model="modalRender" width="700" persistent scrollable no-click-animation overlay-color="overlay">
        <render-pdf :documentType="current.document_type | nameDocumentsType" :number="current.number" :loaderRender="loaderRender" :renderUrl="renderUrl" @close="modalRender=false" :key="current.number" />
      </v-dialog>
      <!-- end dialog pdf -->
    </v-col>
  </v-row>
</template>
<script>
import { mapState } from 'vuex'
import SkeletonList from '@/components/commons/skeletonLoader/SkeletonList'
import VEmptyState from '@/components/commons/VEmptyState'
import RenderPdf from '@/components/files/RenderPdf'
import VTableHeaders from '@/components/VTable/VTableHeaders'
import ListViewMixin from '@/mixins/ListViewMixin'
import GenericViewMixin from '@/mixins/GenericViewMixin'
import RenderViewMixin from '@/mixins/RenderViewMixin'
import ChipStatus from '@/components/commons/ChipStatus'
import VMainPagination from '@/components/commons/VMainPagination'
import PermissionDenied from '@/modules/http/views/PermissionDenied'

export default {
  components: {
    ChipStatus,
    RenderPdf,
    VEmptyState,
    SkeletonList,
    VTableHeaders,
    VMainPagination,
    PermissionDenied
  },
  mixins: [
    ListViewMixin,
    GenericViewMixin,
    RenderViewMixin
  ],
  props: {
    headerList: {
      type: Array,
      default: () => ([])
    },
    pageSize: {
      default: null
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    list: [],
    toggleDropdown: null,
    current: {},
    resetCheckbox: 0,
    isSelected: null,
    selected: [],
    routeList: ['BranchRetrieveUpdate', 'PosRetrieveUpdate', 'DocumentsRetrieve', 'CustomerRetrieveUpdate', 'DocumentsBatchRetrieve', 'SupplierRetrieveUpdate'],
    headersDetail: [
      { text: 'Proveedor', value: 'supplier', sortable: true },
      { text: 'Tipo de documento', value: 'document_type', sortable: true },
      { text: 'Folio #', value: 'number', align: 'end', sortable: true },
      { text: 'Fecha', value: 'date', align: 'end', sortable: true },
      // { text: 'Estado', value: 'status', width: 130 },
      { text: 'Total', value: 'total', align: 'end', sortable: true, width: 135 }
    ]
  }),
  computed: {
    heightTable () {
      if (this.purchasesList.length < 17 && this.$vuetify.breakpoint.height >= 900) return 'auto'
      if (['SupplierRetrieveUpdate'].includes(this.$route.name)) return 'auto'
      return 'calc(100vh - 275px)'
    },
    ...mapState({
      count: state => state.purchases.purchases_from_siiCount,
      purchasesList: state => state.purchases.purchases_from_siiList,
      labelsList: state => state.labels.labelsList
    }),
    hiddenBtnEmpty () {
      if (!Object.keys(this.$route.query ?? {}).length) return true
      else if (this.purchasesList.length) return false
      return false
    },
    currentHeader () {
      let list = this.list
      list.forEach((item) => {
        if (['SupplierRetrieveUpdate'].includes(this.$route.name)) {
          // if (item.value === 'document_type') item.text = 'T.doc'
          if (item.value === 'number') item.text = this.$t('generals.Folio#')
        } else if (this.$route.name === 'PurchaseList') {
          if (this.$vuetify.breakpoint.width < 1210 && item.value === 'document_type') {
            // item.text = 'T. documento'
          }
        }
        if (this.$store.state?.auth?.account?.country === 'PE' && item.value === 'number') {
          item.align = 'start'
          item.text = this.$t('generals.Folio #')
        }
      })
      return !this.$helpers.hasPermission(['add_swapresponse']) ? list.filter((h) => h.value !== 'status') : list
    }
  },
  watch: {
    selected (val) {
      if (val.length !== this.purchasesList.length) this.resetCheckbox += 1

      this.$emit('update:selectedDocuments', this.selected)
    },
    toggleDropdown (val) {
      if (['filtered', 'perPage', 'all'].includes(val)) {
        this.selected = this.purchasesList
        this.isSelected = true
      } else if (val === '') this.selected = []
      this.$emit('update:toggleDropdown', val)
    },
    headerList () {
      this.list = [...this.headerList].filter((item) => item.show)
    }
  },
  methods: {
    toggleAll () {
      this.selected = !this.isSelected ? [] : this.purchasesList
    },
    goTo (id = null) {
      this.$store.commit('base/SET_QUERY', this.$route.query)
      this.$router.push({ name: 'PendingReceiveRetrieve', params: { id: id }})
    }
  }
}
</script>